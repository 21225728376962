<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>EXTRACT MER</h3>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <base-input class="col-md-4 mb-2" label="date">
                      <el-date-picker v-model="StartDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                    </base-input>
                    <!-- <base-input class="col-md-4 mb-2" :label="$t('table.columns.Enddate')">
                      <el-date-picker v-model="EndnDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                    </base-input> -->
                  </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                  <div class="row">
                    <base-button 
                      v-if="$apollo.queries.getStatisticsMyGustaveProductsClient.loading"
                      loading 
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                    <button 
                      v-else
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                      @click="search"
                    >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card ListGlobalTable">
          <div class="card-body">
            <el-table :data="GlobalTable" show-summary v-loading='$apollo.queries.getStatisticsMyGustaveProductsClient.loading' :summary-method="getSummaries">
              <el-table-column
                prop='product'
                :label="$t('table.columns.Product')"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
              </el-table-column>
              <el-table-column
                prop='nb_cotation'
                :label="$t('table.columns.numberOfQuotations')"
                minWidth='150'
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.nb_cotation) }}
                </template>
              </el-table-column>
              <el-table-column
                label="MER"
                prop="mer"
                min-width="90"
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label style="cursor: pointer;" @click="getExtract(scope.row.product, 'mer')">
                    <u>
                      {{ formatThounsends(scope.row.mer) }}
                    </u>
                  </label>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('table.columns.MerRate')"
                prop="taux_mer"
                min-width="140"
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  <label :style="(scope.row.taux_mer < 30) ? 'color: red' : 'color: green'">
                    {{ formatThounsends(scope.row.taux_mer) + " %" }}
                  </label>
                </template>
              </el-table-column>
              <!-- <el-table-column
                :label="$t('table.columns.nameOfLeadsSuiteAbandon')"
                prop="nb_leads_abandonment"
                min-width="270"
                show-overflow-tooltip
                sortable
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.nb_leads_abandonment) }}
                </template>
              </el-table-column> -->
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Select, Option ,DatePicker} from 'element-ui'
import { Table, TableColumn } from 'element-ui';
import gql from 'graphql-tag'
import moment from 'moment'
import XLSX from 'xlsx'

export default {
  apollo: {
    getStatisticsMyGustaveProductsClient: {
      client: 'MARKET',
      query: gql `
        query ($date_debut: String!, $date_fin: String!,$id_utilisateur: Int) {
          getStatisticsMyGustaveProductsClient(date_debut: $date_debut, date_fin: $date_fin, id_utilisateur: $id_utilisateur) {
            #affichage_sante
            #affichage_animaux
            #mer_sante
            #mer_animaux
            #affichage_mrh
            #mer_mrh    
            #affichage_auto
            #mer_auto
            affichage_emprunteur
            mer_emprunteur
            affichage_per
            mer_per
          }
        }
      `,
      variables () {
        return {
          id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
          date_debut: this.StartDate,
          date_fin: this.StartDate
        }
      },
      skip() {
        return this.SkipQuery_Global
      },
      result({ data, loader, networkStatus }) {
        let data_array = [
          // {
          //   product: 'ASSURANCE ANIMAUX', 
          //   nb_cotation: data.getStatisticsMyGustaveProductsClient.affichage_animaux, 
          //   mer: data.getStatisticsMyGustaveProductsClient.mer_animaux, 
          //   taux_mer: data.getStatisticsMyGustaveProductsClient.affichage_animaux !=0 ? ((data.getStatisticsMyGustaveProductsClient.mer_animaux / data.getStatisticsMyGustaveProductsClient.affichage_animaux) * 100).toFixed(2) : 0
          // },
          // {
          //   product: 'ASSURANCE AUTO', 
          //   nb_cotation: data.getStatisticsMyGustaveProductsClient.affichage_auto, 
          //   mer: data.getStatisticsMyGustaveProductsClient.mer_auto, 
          //   taux_mer: data.getStatisticsMyGustaveProductsClient.affichage_auto !=0 ? ((data.getStatisticsMyGustaveProductsClient.mer_auto / data.getStatisticsMyGustaveProductsClient.affichage_auto) * 100).toFixed(2) : 0
          // },
          {
            product: 'ASSURANCE EMPRUNTEUR', 
            nb_cotation: data.getStatisticsMyGustaveProductsClient.affichage_emprunteur, 
            mer: data.getStatisticsMyGustaveProductsClient.mer_emprunteur, 
            taux_mer: data.getStatisticsMyGustaveProductsClient.affichage_emprunteur !=0 ? ((data.getStatisticsMyGustaveProductsClient.mer_emprunteur / data.getStatisticsMyGustaveProductsClient.affichage_emprunteur) * 100).toFixed(2) : 0
          },
          // {
          //   product: 'ASSURANCE HABITATION', 
          //   nb_cotation: data.getStatisticsMyGustaveProductsClient.affichage_mrh, 
          //   mer: data.getStatisticsMyGustaveProductsClient.mer_mrh, 
          //   taux_mer: data.getStatisticsMyGustaveProductsClient.affichage_mrh !=0 ? ((data.getStatisticsMyGustaveProductsClient.mer_mrh / data.getStatisticsMyGustaveProductsClient.affichage_mrh) * 100).toFixed(2) : 0
          // }, 
          // {
          //   product: 'ASSURANCE SANTÉ', 
          //   nb_cotation: data.getStatisticsMyGustaveProductsClient.affichage_sante, 
          //   mer: data.getStatisticsMyGustaveProductsClient.mer_sante,
          //   taux_mer: ((data.getStatisticsMyGustaveProductsClient.mer_sante / data.getStatisticsMyGustaveProductsClient.affichage_sante) * 100).toFixed(2)
          // },
          {
            product: 'ASSURANCE VIE', 
            nb_cotation: data.getStatisticsMyGustaveProductsClient.affichage_per, 
            mer: data.getStatisticsMyGustaveProductsClient.mer_per, 
            taux_mer: data.getStatisticsMyGustaveProductsClient.affichage_per !=0 ? ((data.getStatisticsMyGustaveProductsClient.mer_per / data.getStatisticsMyGustaveProductsClient.affichage_per) * 100).toFixed(2) : 0
          },
        ]

        this.GlobalTable = data_array.filter(x => x.mer !=0)

        this.GlobalTable.forEach(element => {
          if (element.mer != 0 && element.nb_cotation == 0) {
            element.nb_cotation = element.mer;
            element.taux_mer = 100; 
          }
        });
        
        this.SkipQuery_Global = true
      },
      fetchPolicy: 'network-only',
      update: data => data.getStatisticsMyGustaveProductsClient,
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      StartDate: moment().format('YYYY-MM-DD'),
      // EndnDate: moment().format('YYYY-MM-DD'),
      GlobalTable: [],
      SkipQuery_Global: true
    }
  },
  methods: {
    search() {
      this.SkipQuery_Global = false
    },
    formatThounsends: function(value, fixe = false, number = 0){
      value != null ? value = value :value = 0
      if(fixe){
        value = value.toFixed(number)
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    getSummaries(param) {
      const { columns, data } = param;
      let somme_cotation = 0
      let somme_mer = 0
      let somme_taux_mer = 0
      data.forEach(stats => {
        somme_cotation += stats.nb_cotation
        somme_mer += stats.mer
        somme_taux_mer += stats.taux_mer
      });
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        } else if (index === 1) {
          sums[index] = this.formatThounsends(somme_cotation);
          return;
        } else if (index === 2) {
          sums[index] = this.formatThounsends(somme_mer);
          return;
        } else if (index === 3) {
          sums[index] = ((somme_mer / somme_cotation) * 100).toFixed(2) +" %";
          return;
        } else {
          sums[index] = "";
          return;
        }
      });
      return sums;
    },
    async getExtract(product, type) {
      
      if (product === "ASSURANCE EMPRUNTEUR") {
        await this.$apollo.query({
          client: 'MARKET',
          fetchPolicy: 'network-only',
          query: require("src/graphql/mongustave/client/ExtractMerEmprunteur.gql"),
          variables: {
            date_debut: this.StartDate,
            date_fin: this.StartDate,
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          },
        }).then(data => {
          this.downloadCSV(data.data.getMgExtractEmprunteurClient, product)
        }).catch((error) => {
          console.error(error)
        })
        
      } else if (product === "ASSURANCE VIE") {
        await this.$apollo.query({
          client: 'MARKET',
          fetchPolicy: 'network-only',
          query: require("src/graphql/mongustave/client/ExtractMerPer.gql"),
          variables: {
            date_debut: this.StartDate,
            date_fin: this.StartDate,
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          },
        }).then(data => {
          this.downloadCSV(data.data.getMgExtractEmprunteurClient, product)
        }).catch((error) => {
          console.error(error)
        })
      }
      
    },
    downloadCSV(data, product) {
      data.forEach(ele => { 
          delete ele.__typename
      })
      const fileName = `MER_${product}.csv`
      var Stats = XLSX.utils.json_to_sheet(data) 
      Stats = XLSX.utils.sheet_to_csv(Stats, {FS:";"});

      var blob = new Blob(["\ufeff", Stats]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  }
}
</script>